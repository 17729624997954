import * as React from "react"
import Block from "/src/components/header-002"
import BlockHero from "/src/components/hero-001"
import { Icon } from "reflexjs"



  
    export default function Example() {
  return (
    <>
    <Block
      minH="500|0"
      branding={{
        name: "A1'S HOME PAGE",
      }}
      links={[
        {
          title: "SOFTBALL HIGHLIGHTS",
          href: "/softball-highlights",
        },
        {
          title: "SCHEDULE",
          href: "/softball-schedule",
        },
        {
          title: "ACCOMPLISHMENTS",
          href: "/accomplishments-awards",
        },
        {
          title: "2ND SPORT WRESTLING",
          href: "/wrestling-highlights",
        },
      ]}
      buttonLink={{
        title: "NCAA ID# 2009918132",
      }}
    />
    <BlockHero
      subheading="NEWS ALERT"
      heading="Alannah ''A1'' Rogers "
      text="I've committed to the Louisiana Tech softball family. I'd like to thank my parents, my sister, coaches, teachers and all my family members who have supported my goals to play D1 Softball at a highly competitive University. - Thank You!!"
      image={{
        src: "/images/alannah-a1-rogers.png",
        alt: "Alannah A1 Rogers Committed"
      }}
      buttons={
        <div display="inline-grid" col="2" gap="2" mt="4">
          <a variant="button.primary" href="/club-coach-comments">
            Club Coach Comments <Icon name="arrow-right" ml="2" size="4" />
          </a>
          <a variant="button.secondary" href="/high-school-coach-comments">
            H.S. Coach Comments
          </a>
        </div>
      }
  
    />
    </>
  )}

